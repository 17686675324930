(function () {
  'use strict';

  class DiscussionsIndexCtrl {
    constructor(User, Groups, Discussions, $rootScope, Classrooms, Utils) {
      let vm = this;
      vm.User = User;
      vm.Discussions = Discussions;
      vm.$rootScope = $rootScope;
      vm.Groups = Groups;
      vm.discussion = [];
      vm.Classrooms = Classrooms;
      vm.Utils = Utils;
      vm.ctrlName = 'DiscussionsIndexCtrl';
      vm.$rootScope.$on('notificationIndex', function (e, d) {
        vm.setNotification(d);
      });
      vm.init();
    }
    setNotification(d) {
      let vm = this;
      if (vm.discussion && vm.discussion.documents) {
        angular.forEach(vm.discussion.documents, function (group) {
          angular.forEach(group.discussions.documents, function (discussion) {
            if (d.notification.item_id === discussion.id) {
              discussion.newInput = true;
            }
          });
        });
      }
    }
    init() {
      let vm = this;
      vm.User.userView()
        .then((data) => {
          vm.user = data;
          if (!vm.Utils.roleCheck(['superadmin', 'admin', 'Teacher'])) {
            vm.getGroups();
          }
        });
      if (vm.Utils.roleCheck(['superadmin', 'admin'])) {
        vm.Classrooms.classroomsIndex({rows: 10000})
          .then((data)=> {
            vm.classRooms = data;
            if (vm.$rootScope.classroomId) {
              vm.getDiscussions();
            }
          });
      } else if (vm.Utils.roleCheck(['Teacher'])) {
        vm.Classrooms.myClassrooms()
          .then((data)=> {
            vm.classRooms = data;
            if (vm.$rootScope.classroomId) {
              vm.getDiscussions();
            }
          });
      }
    }
    getDiscussions() {
      let vm = this,
          classRoomObj = {};
      if (vm.Utils.roleCheck(['superadmin', 'admin', 'Teacher'])) {
        classRoomObj.classroom_id = vm.$rootScope.classroomId;
      }
      if (vm.$rootScope.classroomId) {
        vm.Discussions.activeDiscussionView(classRoomObj)
          .then((data)=> {
            vm.discussion = data;
          });
      }
    }
    getGroups() {
      let vm = this;
      vm.Groups.groupIndex({id: vm.user.groups[0].id})
        .then((data)=>{
          vm.group = data;
          vm.$rootScope.classroomId = vm.group.classroom.id;
          vm.getDiscussions();
        });
    }
  }

  /**
   * @ngdoc object
   * @name discussions.index.controller:DiscussionsIndexCtrl
   *
   * @description
   *
   */
  angular
    .module('discussions.index')
    .controller('DiscussionsIndexCtrl', DiscussionsIndexCtrl);
}());
